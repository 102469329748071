import { useRouter } from 'next/router'
import { useCallback, useMemo } from 'react'
import { createContainer } from 'unstated-next'

import useSwr from '../../../hooks/use-swr'
import ArtistApis from '../api'
import artistParser, { followingArtistParser } from '../utils/parser'

const ArtistStore = createContainer((initialData) => {
  const {
    query: { livdemyId = '' },
    isFallback,
  } = useRouter()

  const lowerCasedLivdemyId = useMemo(() => livdemyId.toLowerCase(), [
    livdemyId,
  ])

  const { data, error } = useSwr({
    condition: !isFallback && Boolean(lowerCasedLivdemyId),
    initialData,
    parser: artistParser,
    server: 'public-cache',
    url: `profile/${lowerCasedLivdemyId}`,
  })

  const { data: following, revalidate } = useSwr({
    condition: data?.profileType === 'teacher',
    initialData: false,
    parser: followingArtistParser,
    url: `teacher/${lowerCasedLivdemyId}`,
  })

  const follow = useCallback(async () => {
    const { success } = await ArtistApis.follow(livdemyId)

    if (success) {
      await revalidate()
    }

    return success
  }, [livdemyId, revalidate])

  const unfollow = useCallback(async () => {
    const { success } = await ArtistApis.unfollow(livdemyId)

    if (success) {
      await revalidate()
    }

    return success
  }, [livdemyId, revalidate])

  return {
    data,
    follow,
    following,
    loadFailed: Boolean(error) && !data,
    loading: Boolean(!(data || error)),
    unfollow,
  }
})

export default ArtistStore
