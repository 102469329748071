import Api from '../../../api/configuration'

const ArtistApis = {
  follow: (livdemyId) =>
    Api.coreProtected.post(`follow/livdemy-id/${livdemyId}`),
  unfollow: (livdemyId) =>
    Api.coreProtected.post(`unfollow/livdemy-id/${livdemyId}`),
}

export default ArtistApis
