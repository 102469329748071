import { flatten, o, pipe, values } from 'ramda'

import Artist from '../../../components/cards/artist/artist'
import BroadcastClass from '../../../components/cards/broadcast-class/broadcast-class'
import PublicRecordedCourse from '../../../components/cards/course/public/recorded'
import PublicCourse from '../../../components/cards/course/public/regular'
import Carousel from '../../../components/carousel'
import List from '../../../components/list'
import { H6, Text } from '../../../styles/typography'
import dimensions from '../../../utils/dimensions'
import eventsSort from '../../../utils/events-sort'
import { SeparatorEl } from '../elements'
import { CourseEl } from '../elements/course'
import ArtistStore from '../stores/artist-store'
import { useEffect } from 'react'

const Courses = () => {
  const {
    data: {
      liveCourses,
      recordedCourses,
      broadcastClasses,
      classes,
      artists,
      profileType,
      course,
      BrodcastCourse,
      otherBrodcastCourse,
    },
  } = ArtistStore.useContainer()

  const allBroadcastClasses = pipe(
    values,
    flatten,
    eventsSort,
  )(broadcastClasses)
  useEffect(() => {
    console.log('course,BrodcastCourse', otherBrodcastCourse)
  }, [BrodcastCourse])

  return (
    <CourseEl>
      {Boolean(allBroadcastClasses.length) && (
        <>
          <H6>Live Classes</H6>
          <SeparatorEl />
          <Carousel
            data={allBroadcastClasses}
            item={(data, params) => (
              <BroadcastClass data={data} params={params} />
            )}
            itemWidth={dimensions.vw(60) + dimensions.rem(2)}
          />
        </>
      )}
      {Boolean(recordedCourses.length) && (
        <>
          <H6>Recorded Courses</H6>
          <SeparatorEl />
          <List
            data={recordedCourses}
            defaultCount={3}
            item={(data, params) => (
              <PublicRecordedCourse data={data} params={params} />
            )}
          />
        </>
      )}
      {Boolean(liveCourses.length) && (
        <>
          <H6>Live Courses</H6>
          <SeparatorEl />
          <Carousel
            data={liveCourses}
            item={(data, params) => (
              <PublicCourse data={data} params={params} />
            )}
            itemWidth={dimensions.vw(60) + dimensions.rem(2)}
          />
        </>
      )}
      {Boolean(classes.length) && (
        <>
          <H6>Live Courses</H6>
          <SeparatorEl />
          <Carousel
            data={classes.filter(
              (cls) =>
                profileType !== 'school' ||
                cls.url !==
                  '/followingmahesh/course/basic-music-production-on-logic-pro-x',
            )}
            item={(data, params) => (
              <PublicCourse data={data} params={params} />
            )}
            itemWidth={dimensions.vw(60) + dimensions.rem(2)}
          />
        </>
      )}
      {Boolean(course?.length) &&
        Boolean(course.find((ele) => ele.type === 'recorded')) && (
          <>
            <H6>Premium Courses</H6>
            <Text>
              LivDemy’s premium courses provide the most comprehensive material,
              meticulously crafted by experts for structured learning. Some
              courses feature live Q&A sessions for direct interaction and
              feedback.
            </Text>
            <List
              data={course.find((ele) => ele.type === 'recorded').data}
              defaultCount={3}
              item={(data, params) => (
                <PublicRecordedCourse data={data} params={params} />
              )}
            />
          </>
        )}
      {Boolean(course?.length) &&
        Boolean(course.find((ele) => ele.type === 'group')) && (
          <>
            <H6>Group Courses</H6>
            <Text>
              LivDemy’s "Live" group courses offer personalized attention in
              small settings, fostering peer learning. Classes are recorded for
              convenient review
            </Text>
            <Carousel
              data={course.find((ele) => ele.type === 'group').data}
              item={(data, params) => (
                <PublicCourse data={data} params={params} />
              )}
              itemWidth={dimensions.vw(60) + dimensions.rem(2)}
            />
          </>
        )}
      {(BrodcastCourse?.[0]?.length || BrodcastCourse?.[1]?.length) &&
        Boolean([].concat(...BrodcastCourse)?.length) && (
          <>
            <H6>Masterclass/Workshops</H6>
            <Text>
              Livdemy's Masterclasses and Workshops feature insights and tips
              from top artists and educators, ensuring excellence in your craft.
              With LivDemy Pro membership, access the complete archive for
              continuous learning.
            </Text>
            <Carousel
              data={[].concat(...BrodcastCourse)}
              item={(data, params) => (
                <BroadcastClass data={data} params={params} />
              )}
              itemWidth={dimensions.vw(60) + dimensions.rem(2)}
            />
          </>
        )}
      {Boolean(otherBrodcastCourse.length) && (
        <>
          <H6>Other Sessions</H6>
          <Text>
            Explore numerous sessions with artists and educators for valuable
            inspiration and insights. These archived sessions often feature
            artist conversations, offering unique perspectives on their craft.
          </Text>
          <Carousel
            data={[].concat(...otherBrodcastCourse)}
            item={(data, params) => (
              <BroadcastClass data={data} params={params} />
            )}
            itemWidth={dimensions.vw(60) + dimensions.rem(2)}
          />
        </>
      )}
      {Boolean(artists.length) && (
        <>
          <H6>Artists</H6>
          <Text>
            Top artists and educators in this field offer detailed courses and
            live classes, dedicated to enhancing your skills and inspiring your
            musical journey toward excellence.
          </Text>
          <SeparatorEl />
          <List
            data={artists || []}
            defaultCount={3}
            item={(itemData, params) => (
              <Artist data={itemData} params={params} />
            )}
          />
        </>
      )}
    </CourseEl>
  )
}

export default Courses
