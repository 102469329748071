import { IconButton } from '@material-ui/core'
import { ArrowRight } from '@material-ui/icons'
import { useMotionValue, useTransform } from 'framer-motion'
import Image from 'next/image'
import Link from 'next/link'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import LinesEllipsis from 'react-lines-ellipsis'

import { H5, S05, Text } from '../../../../styles/typography'
import getCurrencySymbol from '../../../../utils/currency-symbol'
import dimensions from '../../../../utils/dimensions'
import computeFee from '../../../../utils/fee'
import { PublicCourseEl } from '../../elements/public-course'

const PublicCourse = ({
  data: {
    key,
    id,
    cover,
    name,
    artist,
    url,
    fee,
    currency,
    discount,
    duration,
  },
  params: { index, width, x },
}) => {
  const temp = useMotionValue(0)

  const input = [-width * (index + 1), -width * (index + 0.5), -width * index]
  const output = [0, 0, 1]
  const opacity = useTransform(x || temp, input, output)

  const { original, isDiscounted, final } = useMemo(
    () => computeFee(fee / duration, discount),
    [fee, duration, discount],
  )

  const currencySymbol = useMemo(() => getCurrencySymbol(currency), [currency])

  return (
    <Link href={url} passHref>
      <a>
        <PublicCourseEl key={key || id} style={{ opacity }}>
          <div className='content'>
            <div className='cover'>
              {cover && (
                <Image
                  height={dimensions.dw(60)}
                  priority
                  quality={50}
                  src={cover}
                  width={dimensions.dw(60)}
                />
              )}
            </div>
            <LinesEllipsis component={Text} maxLine='2' text={name} />
            <S05 className='artist'>
              By <span>{artist}</span>
            </S05>
            <div className='row'>
              <div className='fee'>
                {isDiscounted && (
                  <S05>
                    {currencySymbol} {original}
                  </S05>
                )}
                <H5>
                  {currencySymbol} {final}
                  <span>/ class</span>
                </H5>
              </div>
              <IconButton>
                <ArrowRight />
              </IconButton>
            </div>
          </div>
        </PublicCourseEl>
      </a>
    </Link>
  )
}

export default PublicCourse

PublicCourse.propTypes = {
  data: PropTypes.shape({
    artist: PropTypes.string.isRequired,
    cover: PropTypes.string.isRequired,
    currency: PropTypes.string.isRequired,
    discount: PropTypes.number,
    duration: PropTypes.number.isRequired,
    fee: PropTypes.number.isRequired,
    id: PropTypes.string,
    key: PropTypes.string,
    name: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
  params: PropTypes.shape({
    index: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    x: PropTypes.any.isRequired,
  }).isRequired,
}
