import isServer from './is-server'

const canShare = Boolean(!isServer() && navigator.share)

const share = ({ title, text, url, files }, fallbackString) =>
  canShare
    ? navigator.share({
        title,
        text,
        url,
        files,
      })
    : navigator.clipboard?.writeText(fallbackString).then(() => {
        const { enqueueSnackbar = (x, y) => [x, y], closeSnackbar = (x) => x } =
          window.snackbar || {}

        const key = enqueueSnackbar('Copied the link to clipboard!', {
          variant: 'success',
          onClick: () => closeSnackbar(key),
        })
      })

export default share
