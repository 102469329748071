import Head from 'next/head'

import stripHtmlTags from '../../../utils/strip-html-tags'
import ArtistStore from '../stores/artist-store'

const Meta = () => {
  const {
    data: { profileType, profile, school },
  } = ArtistStore.useContainer()

  const { cover, image, name, description } =
    profileType === 'school' ? school : profile

  return (
    <Head>
      <title>{name} | LivDemy</title>
      <meta content={`${name} | LivDemy`} name='og:title' property='og:title' />
      <meta
        content={stripHtmlTags(description)}
        name='description'
        property='description'
      />
      <meta
        content={stripHtmlTags(description)}
        name='og:description'
        property='og:description'
      />
      <meta content={cover || image} name='og:image' property='og:image' />
      <meta
        content={cover || image}
        name='og:image:secure'
        property='og:image:secure'
      />
      <meta
        content={cover || image}
        name='og:image:secure_url'
        property='og:image:secure_url'
      />
    </Head>
  )
}

export default Meta
