/* eslint-disable import/prefer-default-export */
import styled from 'styled-components'
import { hFlex } from '../../../styles/style-helpers'

export const ActionsEl = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: black;
  padding: 1em;
  ${hFlex};
  justify-content: space-between;

  button {
    width: calc(50% - 0.5em);
  }
`
