import AddIcon from '@material-ui/icons/Add'
import CheckIcon from '@material-ui/icons/Check'
import ShareIcon from '@material-ui/icons/Share'
import { useRouter } from 'next/router'
import { useMemo } from 'react'

import Button from '../../../components/button'
import share from '../../../utils/share-content'
import { ActionsEl } from '../elements/actions'
import ArtistStore from '../stores/artist-store'

const Actions = () => {
  const {
    query: { livdemyId },
  } = useRouter()

  const {
    data: {
      profile: { name, description },
      profileType,
    },
    following,
    follow,
    unfollow,
  } = ArtistStore.useContainer()

  const pageLink = useMemo(() => `https://www.livdemy.com/${livdemyId}`, [
    livdemyId,
  ])

  return profileType !== 'teacher' ? null : (
    <ActionsEl>
      <Button
        onClick={() =>
          share(
            {
              text: description,
              title: `${name} | LivDemy`,
              url: pageLink,
            },
            pageLink,
          )
        }
        startIcon={<ShareIcon />}
        theme='secondary'
      >
        Share
      </Button>
      <Button
        onClick={() => (following ? unfollow() : follow())}
        requiresLogin
        startIcon={following ? <CheckIcon /> : <AddIcon />}
        theme='primary'
      >
        {following ? `Following` : `Follow`}
      </Button>
    </ActionsEl>
  )
}

export default Actions
