import { useEffect } from 'react'

import FullPage from '../../components/full-page'
import loader from '../../components/loader'
import TopBarStore from '../../modules/navigation/stores/top-bar-store'
import withStore from '../../utils/with-store'
import About from './components/about'
import Actions from './components/actions'
import Courses from './components/courses'
import Header from './components/header'
import Meta from './components/meta'
import { ArtistEl } from './elements'
import ArtistStore from './stores/artist-store'

const Artist = () => {
  const { loading, loadFailed } = ArtistStore.useContainer()
  const { toggleDark } = TopBarStore.useContainer()

  useEffect(() => {
    toggleDark(true)

    return () => toggleDark(false)
  }, [toggleDark])

  return loader(
    loading,
    loadFailed,
    <FullPage type='show-header'>
      <ArtistEl>
        <Meta />
        <Header />
        <About />
        <Courses />
        <Actions />
      </ArtistEl>
    </FullPage>,
  )
}

export default withStore([ArtistStore])(Artist)
