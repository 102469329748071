/* eslint-disable import/prefer-default-export */
import styled from 'styled-components'
import { H6 } from '../../../styles/typography'
import { SeparatorEl } from './index'

export const CourseEl = styled.div`
  position: relative;
  width: 100%;
  margin: 4em 0;

  ${H6} {
    text-transform: uppercase;
    padding: 0 1em;
    margin-top: 2em;
  }

  ${SeparatorEl} {
    margin-left: 1em;
  }
  > p {
    padding: 0 1rem;
    line-height: 1.5;
    margin: 2em 0;
    color: #a0a0a0;
  }
`
