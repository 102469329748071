import PropTypes from 'prop-types'

import useTime from '../../hooks/use-time'
import { FullPageEl } from './elements'

const FullPage = ({ children, type }) => {
  useTime(2000)

  return type === 'disabled' ? (
    children
  ) : (
    <FullPageEl type={type}>{children}</FullPageEl>
  )
}

export default FullPage

FullPage.propTypes = {
  children: PropTypes.element.isRequired,
  type: PropTypes.oneOf(['default', 'show-header', 'show-tabs', 'disabled']),
}

FullPage.defaultProps = {
  type: 'default',
}
