/* eslint-disable import/prefer-default-export */
import { motion } from 'framer-motion'
import styled from 'styled-components'

import { accent, background2 } from '../../../styles/colors'
import { center, hFlex, square, vFlex } from '../../../styles/style-helpers'

export const PublicCourseEl = styled(motion.div)`
  display: block;
  height: calc(100% - 1em);
  width: calc(60vw + 2em);
  ${hFlex};
  padding: 0 1em;
  margin: 0.5em 0;

  .content {
    background: ${background2};
    height: 100%;
    width: 100%;
    ${vFlex};
    border-radius: 0.5em;
    padding-bottom: 1em;

    .cover {
      ${square('calc(60vw - 2em)')};
      margin: 1em 0;
      background: #333;
      border-radius: 0.5em;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    p {
      padding: 0 1em;
      width: 100%;
      font-weight: bold;
      line-height: 1.33;

      &.artist {
        font-weight: normal;
        margin: 0.5em 0 2em;

        span {
          font-weight: bold;
          color: ${accent};
        }
      }

      &.startTime {
        font-weight: normal;
      }
    }

    .row {
      width: 100%;
      ${hFlex};
      justify-content: space-between;
      padding: 0 1em;

      .MuiIconButton-root {
        background: #555;
        border-radius: 0.25em;
        padding: 0.25em !important;

        .MuiSvgIcon-root {
          fill: #e7e7e7;
        }
      }

      .fee {
        opacity: ${({ hideFee }) => (hideFee ? 0 : 1)};

        p {
          width: fit-content;
          position: relative;
          color: #777;

          &:after {
            content: '';
            ${center};
            width: 100%;
            height: 1px;
            background: #e7e7e7;
          }
        }

        h5 {
          color: #e7e7e7;

          span {
            margin-left: 0.5em;
            font-size: 0.64em;
            letter-spacing: 0.025em;
          }
        }
      }
    }
  }
`
