/* eslint-disable import/prefer-default-export */
import styled from 'styled-components'
import { accent, background1 } from '../../../styles/colors'

export const ArtistEl = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: auto;
  padding-bottom: 5em;
  background: ${background1};
`

export const SeparatorEl = styled.span`
  display: block;
  width: 5em;
  height: 1px;
  background: ${({ accented }) => (accented ? accent : `rgba(0, 0, 0, 0.15)`)};
  margin: 1.5em 0;
`
