import { AboutEl, AboutText } from '../elements/about'
import ArtistStore from '../stores/artist-store'

const About = () => {
  const {
    data: { profileType, profile, school },
  } = ArtistStore.useContainer()

  const { description } = profileType === 'school' ? school : profile

  return (
    <AboutEl>
      <AboutText>{description}</AboutText>
    </AboutEl>
  )
}

export default About
