import { IconButton } from '@material-ui/core'
import { ArrowRight } from '@material-ui/icons'
import Image from 'next/image'
import Link from 'next/link'
import PropTypes from 'prop-types'
import LinesEllipsis from 'react-lines-ellipsis'

import { S05, Text } from '../../../styles/typography'
import dimensions from '../../../utils/dimensions'
import { ArtistEl } from '../elements/artist'

const Artist = ({
  data: { key, id, cover, instruments, location, name, url },
}) => {
  return (
    <Link href={url}>
      <a>
        <ArtistEl key={key || id}>
          <div className='content'>
            <div className='cover'>
              {cover && (
                <Image
                  height={dimensions.dw(60)}
                  priority
                  quality={50}
                  src={cover}
                  width={dimensions.dw(60)}
                />
              )}
            </div>
            <div className='info'>
              <LinesEllipsis component={Text} maxLine='2' text={name} />
              <S05 className='teaches'>
                <span>{instruments}</span>
              </S05>
              <div className='row'>
                <div className='location'>
                  <S05>{location}</S05>
                </div>
                <IconButton>
                  <ArrowRight />
                </IconButton>
              </div>
            </div>
          </div>
        </ArtistEl>
      </a>
    </Link>
  )
}

export default Artist

Artist.propTypes = {
  data: PropTypes.shape({
    cover: PropTypes.string.isRequired,
    id: PropTypes.string,
    instruments: PropTypes.string.isRequired,
    key: PropTypes.string,
    location: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
}
