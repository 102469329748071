/* eslint-disable import/prefer-default-export */
import styled from 'styled-components'
import { Text } from '../../../styles/typography'

export const AboutEl = styled.div`
  position: relative;
  width: 100%;
  padding: 0 1em;
  margin: 4em 0;
`

export const AboutText = styled(Text)`
  position: relative;
  line-height: 1.5;
  white-space: pre-wrap;
`
