/* eslint-disable import/prefer-default-export */
import styled, { css } from 'styled-components'

import navigationDimensions from '../../../modules/navigation/config'
import { overlay } from '../../../styles/style-helpers'

export const FullPageEl = styled.div`
  ${overlay};
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: auto;
  transform: translate(0, 0);
  z-index: ${navigationDimensions.mobileTopBarZIndex + 1};

  ${({ type }) =>
    type === 'show-header'
      ? css`
          top: ${navigationDimensions.topBarHeightMobile}em;
          height: calc(100% - ${navigationDimensions.topBarHeightMobile}em);
        `
      : ''}
`
