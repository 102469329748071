import Image from 'next/image'

import { H4, Text } from '../../../styles/typography'
import dimensions from '../../../utils/dimensions'
import s3LinkParser from '../../../utils/s3-link'
import { SeparatorEl } from '../elements'
import { HeaderEl } from '../elements/header'
import ArtistStore from '../stores/artist-store'

const Header = () => {
  const {
    data: { profileType, profile, school },
  } = ArtistStore.useContainer()

  const { cover, image, name, teachingAreas = [], location } =
    profileType === 'school' ? school : profile

  return (
    <HeaderEl>
      <div className='cover'>
        {(image || cover) && (
          <Image
            height={dimensions.dw(90)}
            priority
            quality={50}
            src={s3LinkParser(image || cover)}
            width={dimensions.dw(90)}
          />
        )}
      </div>
      <H4 bold>{name}</H4>
      <SeparatorEl accented />
      <Text>{location || teachingAreas.join(', ')}</Text>
    </HeaderEl>
  )
}

export default Header
