import { applySpec, pathOr, pipe, propOr } from 'ramda'

import addId from '../../../utils/add-id'
import eventsSort from '../../../utils/events-sort'

const artistParser = applySpec({
  artists: propOr({}, 'artists'),
  broadcastClassSeries: applySpec({
    btm: pipe(pathOr([], ['broadcastClassSeries', 'btm']), addId, eventsSort),
    concert: pipe(
      pathOr([], ['broadcastClassSeries', 'concert']),
      addId,
      eventsSort,
    ),
    demo: pipe(pathOr([], ['broadcastClassSeries', 'demo']), addId, eventsSort),
    masterclass: pipe(
      pathOr([], ['broadcastClassSeries', 'masterclass']),
      addId,
      eventsSort,
    ),
    qna: pipe(pathOr([], ['broadcastClassSeries', 'qna']), addId, eventsSort),
    workshop: pipe(
      pathOr([], ['broadcastClassSeries', 'workshop']),
      addId,
      eventsSort,
    ),
  }),
  broadcastClasses: applySpec({
    btm: pipe(pathOr([], ['broadcastClasses', 'btm']), addId, eventsSort),
    concert: pipe(
      pathOr([], ['broadcastClasses', 'concert']),
      addId,
      eventsSort,
    ),
    demo: pipe(pathOr([], ['broadcastClasses', 'demo']), addId, eventsSort),
    masterclass: pipe(
      pathOr([], ['broadcastClasses', 'masterclass']),
      addId,
      eventsSort,
    ),
    qna: pipe(pathOr([], ['broadcastClasses', 'qna']), addId, eventsSort),
    workshop: pipe(
      pathOr([], ['broadcastClasses', 'workshop']),
      addId,
      eventsSort,
    ),
  }),
  course: propOr({}, 'course'),
  BrodcastCourse: propOr({}, 'BrodcastCourse'),
  otherBrodcastCourse: propOr({}, 'otherBrodcastCourse'),
  classes: pipe(propOr([], 'classes'), addId, eventsSort),
  liveCourses: pipe(propOr({}, 'liveCourses'), addId, eventsSort),
  profile: propOr({}, 'profile'),
  profileType: propOr('teacher', 'profileType'),
  recordedCourses: pipe(propOr({}, 'recordedCourses'), addId, eventsSort),
  school: propOr({}, 'school'),
})

export const followingArtistParser = propOr(false, 'doFollow')

export default artistParser
