/* eslint-disable import/prefer-default-export */
import styled from 'styled-components'
import { background0, background1 } from '../../../styles/colors'
import { vFlex } from '../../../styles/style-helpers'
import { H4, Text } from '../../../styles/typography'
import { SeparatorEl } from './index'

export const HeaderEl = styled.div`
  width: 100%;
  background: ${background0};
  color: white;
  ${vFlex};
  justify-content: center;
  align-items: flex-start;

  .cover {
    position: relative;
    width: 100%;
    padding: 2em 0;
    background: linear-gradient(
      to bottom,
      ${background1},
      ${background1} 50%,
      ${background0} 50%,
      ${background0}
    );

    > div {
      width: 100% !important;

      > div {
        padding-bottom: 0 !important;
        display: none !important;
      }

      > img {
        position: relative !important;
        top: 0;
        left: 0;
        min-width: unset !important;
        max-width: unset !important;
        min-height: unset !important;
        max-height: unset !important;
        height: 85vw !important;
        width: 85vw !important;
        border-radius: 0.75em;
        margin: 0 7.5vw;
        object-fit: cover;
      }
    }
  }

  & > ${SeparatorEl} {
    margin: 0.75em auto;
  }

  & > ${H4} {
    text-transform: uppercase;
    width: 100%;
    text-align: center;
    padding: 0 7.5vw;
  }

  & > ${Text} {
    width: 100%;
    text-align: center;
    line-height: 1.5;
    padding: 0 7.5vw;
    color: #c7c7c7;
    margin-bottom: 2em;
  }
`
