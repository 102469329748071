import Api from '../../api/configuration'
import generateStaticProps from '../../api/configuration/helper/generate-static-props'
import Artist from '../../app/artist'
import artistParser from '../../app/artist/utils/parser'

export default Artist

export async function getStaticPaths() {
  const artists = await Api.corePublic
    .get(`all-teachers/v2`)
    .then(({ result = [] }) =>
      result.map(({ livdemyId }) => ({
        params: { livdemyId },
      })),
    )

  return {
    fallback: 'blocking',
    paths: process.env.VERCEL_GIT_COMMIT_REF === 'main' ? artists : [],
  }
}

export async function getStaticProps({ params: { livdemyId } }) {
  return generateStaticProps(`teacher/profile/${livdemyId}`, artistParser, {
    server: 'public-cache',
  })
}
