/* eslint-disable import/prefer-default-export */
import { motion } from 'framer-motion'
import styled from 'styled-components'

import { background2 } from '../../../styles/colors'
import { hFlex, square, vFlex } from '../../../styles/style-helpers'

export const ArtistEl = styled(motion.div)`
  display: block;
  width: 100%;
  ${hFlex};
  padding: 0 1em;
  margin: 1em 0;

  .content {
    background: ${background2};
    height: 100%;
    width: 100%;
    ${hFlex};
    align-items: unset;
    border-radius: 0.5em;
    padding: 1em;

    .cover {
      ${square('30vw')};
      margin-right: 1em;
      background: #333;
      border-radius: 0.5em;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .info {
      width: 100%;
      flex: 1;
      ${vFlex};

      > p {
        width: 100%;
        font-weight: bold;
        line-height: 1.33;

        &.teaches {
          font-weight: normal;
          margin: 0.5em 0 0;
          flex: 1;

          span {
            opacity: 0.6;
          }
        }

        &.startTime {
          font-weight: normal;
        }
      }

      .row {
        width: 100%;
        ${hFlex};
        justify-content: space-between;

        .MuiIconButton-root {
          background: #555;
          border-radius: 0.25em;
          margin-left: 0.5em;
          padding: 0.25em !important;
        }

        .location {
          p {
            color: #e7e7e7;
          }
        }
      }
    }
  }
`
